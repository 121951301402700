import API from "../../configs/api";
import apiSTNHDMethod from "@utility/ApiSTNHDMethod";

export const getListCity = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(`${API.GET_COMMON_GET_CITY}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListDistrict = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(
      `${API.GET_COMMON_GET_DISTRICT}`,
      {
        params: payload,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
