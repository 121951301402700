import {
  ENVIRONMENT_VERSION_DESKTOP,
  ENVIRONMENT_FIRE_BASE_SECURITY,
} from "./environment";

export const FIRE_BASE_SECURITY = ENVIRONMENT_FIRE_BASE_SECURITY;
export const VERSION_DESKTOP = ENVIRONMENT_VERSION_DESKTOP;

export const APP_ID = 1; //Elearning: 1, Gia sư: 2

//API STATUS
export const SUCCESS = 200;
export const BAD_REQUEST = 400;
export const SESSION_EXPIRED = 401;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;
export const POST_SUCCESS = 201;
export const DELETE_SUCCESS = 204;
export const RETCODE_SUCCESS = 0;
export const RETTEXT_SUCCESS = "Ok";

// PARAM QUERY
export const PAGE_DEFAULT = 1;
export const LIMIT_DEFAULT = 12;

// PARAM QUERY

// ROUTES
export const ROUTES = {
  PRODUCT: "PRODUCT",
  PRODUCT_DETAIL: "PRODUCT_DETAIL",
  ORDER: "ORDER",
  SELECT_BRANCH: "SELECT_BRANCH",
};

//ACTION SHEET
export const CANCEL_INDEX = 0;
export const PICK_IMAGE_OPTIONS = [
  "Huỷ",
  "Chọn từ bộ sưu tập ảnh",
  "Chụp hình",
];

// export const PAYMENT_STEP_LIST = [
//   {
//     title: "order",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryOrderDetails.name
//   },
//   {
//     title: "payment_method",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryPaymentMethod.name
//   },
//   {
//     title: "payment_finish",
//     route:
//       PaymentRoutesConfig.PaymentStack.screens.TopTabs.screens
//         .SuperSchoolMemoryPaymentFinish.name
//   }
// ]

export const CLASS = [
  { name: "Lớp mầm", code: "1" },
  { name: "Lớp chồi", code: "2" },
  { name: "Lớp lá", code: "3" },
];
export const CLOTHES_SIZE = [
  { name: "S", code: "1" },
  { name: "M", code: "2" },
  { name: "L", code: "3" },
  { name: "XL", code: "4" },
  { name: "XXL", code: "5" },
  { name: "XXL", code: "6" },
];
export const GENDER = [
  { name: "Nam", code: "1" },
  { name: "Nữ", code: "2" },
];

export const LIST_RATE_EVALUATE = [
  { name: "Rất không hài lòng", code: 1 },
  { name: "Không hài lòng", code: 2 },
  { name: "Bình thường", code: 3 },
  { name: "Rất tốt", code: 4 },
  { name: "Tuyệt vời", code: 5 },
];

export const LIST_TARGET_TYPE = [
  {
    code: "Affiliate",
    name: "Điểm danh",
  },
  {
    code: "UserAttendance",
    name: "Giới thiệu bạn bè",
  },
  {
    code: "Event",
    name: "Sự kiện",
  },
];

export const CONTENT_EXAM_TYPE = [
  {
    code: 0,
    value: "Về cuộc thi",
  },
  {
    code: 1,
    value: "Thể lệ và điều khoản",
  },
];

export const TYPE_NOTIFICATION_THVV = [
  {
    code: "news",
    value: "Tin tức",
  },
  {
    code: "event",
    value: "Sự kiện",
  },
  {
    code: "other_noti",
    value: "Khác",
  },
];

export const RATE_START_EVALUATE = [
  {
    code: 1,
    value: "Rất không hài lòng",
  },
  {
    code: 2,
    value: "Không hài lòng",
  },
  {
    code: 3,
    value: "Bình thường",
  },
  {
    code: 4,
    value: "Rất tốt",
  },
  {
    code: 5,
    value: "Tuyệt vời",
  },
];

export const LIST_DAY = [
  {
    code: "Monday",
    value: "Thứ 2",
  },
  {
    code: "Tuesday",
    value: "Thứ 3",
  },
  {
    code: "Wednesday",
    value: "Thứ 4",
  },
  {
    code: "Thursday",
    value: "Thứ 5",
  },
  {
    code: "Friday",
    value: "Thứ 6",
  },
  {
    code: "Saturday",
    value: "Thứ 2",
  },
  {
    code: "Sunday",
    value: "Chủ nhật",
  },
];

export const HOT_LINE = "0939 279 868";
export const WEBSITE = "https://sieutrinhohocduong.com";
export const PRIVACY_POLICY = "https://sieutrinhohocduong.com/Policy/privacy";
export const NEWS_EVENT = "https://tamtriluc.com/category/tin-su-kiens/";

export const VIDEO_ALLOW = "Allow";
export const VIDEO_DENIED = "AccessDenied";
export const ORDER_CODE = "OTHER";

export const TRIAL_CODE = "TRIAL";
export const OTP_ERROR_CODE = 7;

export const SCREEN_TYPES = {
  JOURNEY_365: "JOURNEY_365",
  DRAW_SKETCH_NOTE: "DRAW_SKETCH_NOTE",
  JOURNEY_TO_CONQUER_STAR: "JOURNEY_TO_CONQUER_STAR",
};

export const FORMAT_TIME = ["mm:ss", "mmss"];
export const FORMAT_DATE = ["DD/MM/YYYY", "DD:MM:YYYY", "DDMMYYYY"];
export const FORMAT_DATIMTE_LIST = [
  "DD/MM/YYYY HH:mm",
  "DD:MM:YYYY HH:mm",
  "DDMMYYYY HHmm",
];

export const FORMAT_DATIMTE_FULL_LIST = [
  "DD/MM/YYYY HH:mm:ss",
  "DD:MM:YYYY HH:mm:ss",
  "DDMMYYYY HHmmss",
];

export const FORMAT_DATETIME = "DD/MM/YYYY HH:mm";
export const FORMAT_DATETIME_FULL = "DD/MM/YYYY HH:mm:ss";

// Validate Constants
export const NUMBER_MIN = 0;
export const QUANTITY_MIN = 1;
export const QUANTITY_MAX = 100000000;
export const MAX_PERCENT = 100;
export const INPUT_MAX = 255;
export const TEXTAREA_MAX = 3200;
export const OTHER_TEXTAREA_MAX = 3200;
export const CURRENCY_MIN = 1000;
export const CURRENCY_MAX = 99999999999;
export const COUNT_USE_TICKET = 1000000000;

export const CODE_MIN = 50;

export const OTHER_CODE_MIN = 2;
export const OTHER_CODE_MAX = 10;
