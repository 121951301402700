export default [
  // { key: "Admin" },
  { key: "Banner" },
  { key: "CategoryLesson" },
  { key: "Course" },
  { key: "CommentLessonVideo" },
  { key: "ChapterLesson" },
  { key: "Class" },
  { key: "ClassLevel" },
  { key: "Exams" },
  { key: "GroupCode" },
  { key: "LandingPage" },
  { key: "LessonDocument" },
  { key: "LessonVideo" },
  { key: "LessonVmindmap" },
  { key: "MemoBook" },
  { key: "MemoBookChapter" },
  { key: "MemoBookPart" },
  { key: "MemoVideo" },
  { key: "MindmapImage" },
  { key: "Notification" },
  { key: "Options" },
  { key: "MstOptions" },
  { key: "Question" },
  { key: "Statistic" },
  { key: "ReviewQuestion" },
  { key: "Roles" },
  { key: "SubjectSection" },
  { key: "SubjectSectionChapter" },
  // { key: "Setting" },
  { key: "Subject" },
  { key: "Ticket" },
  { key: "TrialLearn" },
  { key: "Topic" },
  { key: "UpdateApp" },
  { key: "User" },
  { key: "Certificate" },
  { key: "School" },
  { key: "Package" },
  { key: "CommentMemoVideo" },
  { key: "MailTemplate" },
  { key: "Book" },
  // { key: "VersionApp" },
  { key: "CompatitionManagement" },
  { key: "AwardManager" },
  { key: "ExamManagement" },
  { key: "EventClass" },
  { key: "Prize" },
  { key: "PrizeUser" },
  { key: "EventPrize" },
  { key: "ExamsNasa" },
  { key: "StaticPageNasa" },
  { key: "ImageNasa" },
  { key: "Week" },
  { key: "SaleOrder" },
  { key: "NasaRank" },
  { key: "EBanner" },
  { key: "EPromotion" },
  { key: "Order" },
  { key: "GiftOrder" },
  { key: "OrderDetail" },
  { key: "ProductRecommend" },
  { key: "FlashSale" },
  { key: "OrderGift" },
  { key: "Authenticate" },
  { key: "Dashboard" },
  { key: "SurveyForm" },
  { key: "NotificationGroup" },
  { key: "TrackingVoucher" },
  { key: "InfomationManagement" },
  { key: "Organization" },
  { key: "TicketManagement" },
  { key: "ConfigArmorial" },
  { key: "CategorySuggestion" },
  { key: "RateManagement" },
];
