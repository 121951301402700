// ** React Imports
import ReactDOM from "react-dom";

// @Lib
import { HelmetProvider } from "react-helmet-async";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Ant Design
import "antd/dist/antd.css";

// ** Boostraps
// import 'bootstrap/dist/css/bootstrap.css';

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

// ** image-lightbox
import "react-image-lightbox/style.css";

// ** Lazy load app
import Bootstrap from "./bootstrap";

ReactDOM.render(<HelmetProvider>
  <Bootstrap />
</HelmetProvider>, document.getElementById("root"));