import {
  GET_DATA_FOR_SELECT_SUCCESS,
  SET_DEFAULT_IDENTITY_DATA_SELECT_SUCCESS,
  CLEAR_IDENTITY_DATA_SELECT,
  ADD_DATA_FOR_SELECT_SUCCESS,
  UPDATE_DATA_FOR_SELECT_SUCCESS,
  GET_DATA_FOR_SELECT_REQUEST,
  GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST,

  //Load more
  GET_DATA_LOAD_MORE_FOR_SELECT_SUCCESS,
} from "./actions";
import cloneDeep from "lodash/cloneDeep";

export const get_action_data_select = (identity, origin) =>
  `${identity}_${origin}`;

export const create_tree_list = (list, treeHash, treeKey) => {
  const treeList = [];

  for (let i = 0; i < list.length; i++) {
    const parent = list[i][treeKey];
    if (parent && treeHash[parent]) {
      if (!treeHash[parent].children) {
        treeHash[parent].children = [];
      }

      treeHash[parent].children.push(list[i]);
      continue;
    }

    treeList.push(list[i]);
  }

  return treeList;
};

const single_mode = (identity) => {
  const initialState = {
    listIds: [],
    list: [],
    hash: {},
    pagination: {
      curPage: 1,
      limitPage: 50,
      pageSizeOptions: ["5", "10", "15", "20", "50", "100", "200", "500"],
      showSizeChanger: true,
      totalRows: 0,
      totalPage: 0,
    },
    sorter: {},
    filters: {},
    extendsData: {},
  };

  return function (state = initialState, action) {
    switch (action.type) {
      case get_action_data_select(
        identity,
        GET_DATA_LOAD_MORE_FOR_SELECT_REQUEST
      ):
      case get_action_data_select(identity, GET_DATA_FOR_SELECT_REQUEST): {
        const newState = {
          ...cloneDeep(state),
          loading: true,
        };

        return newState;
      }
      case get_action_data_select(identity, GET_DATA_FOR_SELECT_SUCCESS): {
        const {
          list = [],
          pagination,
          filters,
          sorter,
          extendsData = {},
          treeMode,
          treeKey,
        } = action.payload;

        const listIds = [];

        /* Hash data for find item faster */
        const hash = list.reduce((prev, current, index) => {
          listIds.push(current.id);
          current.index = index;
          prev[current.id] = current;
          return prev;
        }, {});

        const newState = {
          ...cloneDeep(state),
          listIds,
          list,
          treeList: treeMode ? create_tree_list(list, hash, treeKey) : [],
          hash,
          pagination: { ...state.pagination, ...pagination },
          filters,
          sorter,
          extendsData,
          loading: false,
          hasLoadMore:
            pagination.totalPage > state.pagination?.curPage ? true : false,
        };

        return newState;
      }
      case get_action_data_select(
        identity,
        SET_DEFAULT_IDENTITY_DATA_SELECT_SUCCESS
      ): {
        const { list = [], treeMode, treeKey } = action.payload;

        const listIds = [];

        /* Hash data for find item faster */
        const hash = list.reduce((prev, current, index) => {
          listIds.push(current.id);
          current.index = index;
          prev[current.id] = current;
          return prev;
        }, {});

        const newState = {
          ...cloneDeep(state),
          listIds,
          list,
          treeList: treeMode ? create_tree_list(list, hash, treeKey) : [],
          hash,
          loading: false,
        };

        return newState;
      }
      case get_action_data_select(identity, ADD_DATA_FOR_SELECT_SUCCESS): {
        const { res } = action.payload;
        const newState = { ...state };
        newState.listIds = [res.id, ...newState.listIds];
        newState.hash = { ...newState.hash, [res.id]: res };
        newState.list = [res, ...newState.list];
        newState.pagination = {
          ...state.pagination,
          total: state.pagination.total + 1,
        };

        return newState;
      }
      case get_action_data_select(identity, UPDATE_DATA_FOR_SELECT_SUCCESS): {
        const { id, column, data, rowMode, extendsData } = action.payload;
        const newState = { ...state };
        if (extendsData) newState.extendsData = extendsData;
        newState.list = newState.list.map((item) => {
          if (item.id === id) {
            let newItem;

            if (rowMode) {
              newItem = data;
            } else {
              newItem = { ...item, [column]: data };
            }

            return newItem;
          }

          return item;
        });
        newState.hash = {
          ...newState.hash,
          [id]: rowMode ? data : { ...newState.hash[id], [column]: data },
        };

        return newState;
      }
      case get_action_data_select(identity, CLEAR_IDENTITY_DATA_SELECT): {
        return cloneDeep(initialState);
      }

      //Load more
      case get_action_data_select(
        identity,
        GET_DATA_LOAD_MORE_FOR_SELECT_SUCCESS
      ): {
        const {
          list = [],
          pagination,
          filters,
          sorter,
          extendsData = {},
        } = action.payload;

        const listIds = [];

        const newList = [...state.list, ...list];

        /* Hash data for find item faster */
        const hash = newList.reduce((prev, current, index) => {
          listIds.push(current.id);
          current.index = index;
          prev[current.id] = current;
          return prev;
        }, {});

        const newState = {
          ...cloneDeep(state),
          listIds,
          list: newList,
          hash,
          pagination: { ...state.pagination, ...pagination },
          filters,
          sorter,
          extendsData,
          loading: false,
          hasLoadMore:
            pagination.totalPage > state.pagination?.curPage ? true : false,
        };

        return newState;
      }
      default:
        return state;
    }
  };
};

export default function (identity, dynamicMode) {
  return single_mode(identity);
}
